<template>
  <div class="pointer-all ">
    <!--<div v-if="pageContent != null && false" class="tinycity-container">
      <h1>{{ pageContent.Title }}</h1>
      <AreaInfo v-for="(area, idx) in GetAreas()" :key="idx" :areaData="area" />
    </div>-->
  </div>
</template>

<script>
import IOMixin from "../../Mixins/IOMixins.js";
import AreaInfo from "./AreaInfo.vue";
import UISectionMixin from "../../Mixins/UISectionMixin";
import { ViewMode } from "../../Enum/Enums";
export default {
  name: "TinyCity",
  mixins: [IOMixin, UISectionMixin],
  props: {
    Name: {
      type: String,
      default: ViewMode.tinyCity,
    },
  },
  components: { AreaInfo },
  data() {
    return {
      pageContent: null,
      marks: null,
    };
  },
  mounted() {
    this.pageContent = this.$store.state.content.tinyCityData;

    this.OnUISectionMounted();

    this.$store.commit("area/ResetConfig");

    var areaMarkData = Object.values(this.$store.state.area.areas).map(
      (area) => {

        var newObj = {
          Name: area.cmsData.Title,
          SubText: area.cmsData.Description,
          Route: area.cmsData.routeParam + (area.name != "Apartment" ? "/configurator" : "/explore"),
          Pos: area.cmsData.TinyCityPosition,
          Room: "TinyCity",
          IconName: area.cmsData.iconName.SVGTitle,
          css: "tinycity-mark",
        };

        return newObj;
      }
    );

    this.marks = this.$store.state.world.mainScene.sprungMarkenController.SetNewMarks(
      "tinyCity",
      areaMarkData,
      true,
      false,
      true 
    );

    this.marks.map((mark) => {
      mark.element.addEventListener("mouseenter", () => this.MouseEnter(mark));
      mark.element.addEventListener("mouseleave", () => this.MouseLeave(mark));
    });

    this.$store.state.world.mainScene.funfactController.SetNewMarks(this.$store.state.content.tinyCityData.funfacts, true, false);

  },
  methods: {
    MouseEnter(mark) {
      this.$store.state.world.mainScene.rooms.TinyCity.SetSelection(
        mark.element.userData.name,
        true
      );
    },
    MouseLeave(mark) {
      this.$store.state.world.mainScene.rooms.TinyCity.SetSelection(
        mark.element.userData.name,
        false
      );
    },
    GetAreas() {
      var areaData = Object.values(this.$store.state.area.areas).map((area) => {
        return area.cmsData;
      });

      return areaData;
    },
  },
  watch: {},
};
</script>
<style lang="scss" scoped>
.tinycity-container {
  padding: 200px;
}
</style>

<style lang="scss" >

.fun-fact{
  opacity: 0;
  animation : blendIn 2s forwards;
}

@for $i from 1 to 10{
  .fun-fact:nth-child(#{$i}){
    animation-delay: .05s * $i;
  }
}

@keyframes blendIn {
  0%{
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
}


.funfact-container {
  padding: 1rem;
  background: $orange;
  font-size: 1rem;
  color: #fff;
  border-radius: 11px;
  max-width: 300px;
  cursor: pointer;

  h2{
    margin-bottom: 0;
   font-size: 1.4rem;
  }
  h2,p{
    color:#fff;
  }
  p,img{
    display: none;
    margin-bottom: 0;
  }
  img{
    margin-top: 1rem;
  }
  p{
   font-size: 1.7rem;
  line-height: 2rem;
  }

  &.visible{
     h2{
      margin-bottom: 0.75rem;
    }
    p,img{
      display: block;
    }
  }
}


</style>