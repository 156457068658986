<template>
<div class="wrapper">
  <div class="badge-image" v-if="pageContent != null && pageContent.BadgeImage != null" :style="{'background-image':'url('+ mainConfig.CMS_BASE_URL + pageContent.BadgeImage.url +')'}"></div>
  <div class="home wrapper">
    <div class="wrapper-inner flex flex-align-center">
      <div class="home-content">
        <h2>{{pageContent.Subtitle}}</h2>
        <h1>{{pageContent.Title}}</h1>
        <div class="flex">
          <RouterLink @click.native="EnableAudio" class="pointer-all start-button card-margin-right" to="/desktop/tinycity" v-if="pageContent != null">{{pageContent.ButtonText}}</RouterLink>
          <div class="icon-button" ref="VRButton"></div>
        </div>
      </div>
      <div class="title-image" v-if="pageContent.TitleImage != null" :style="{'background-image' : 'url(' + mainConfig.CMS_BASE_URL + pageContent.TitleImage.url + ')'}"></div>
      <div class="title-image-left" v-if="pageContent.Title_image_left != null" :style="{'background-image' : 'url(' + mainConfig.CMS_BASE_URL + pageContent.Title_image_left.url + ')'}"></div>
    </div>
  </div>
  

<footer class="">
  <div class="flex flex-between flex-align-bottom">
    <div class="flex sponsors" v-if="pageContent != null">
      <div class="sponsor-logo-image" v-for="sponsor in pageContent.SponsorImage" v-bind:key="'link-' + sponsor.id">
       <img v-if="sponsor.Image != null" :src="mainConfig.CMS_BASE_URL + sponsor.Image.url" :alt="sponsor.Image.alternativeText"/>
      </div>
    </div>
    <!-- <div class="width-6">
      <p class="description">{{pageContent.Description}}</p>
    </div> -->
    <div class="pointer-all width-2 align-right">
      
      <router-link class="imprint" to="/imprint">Impressum & Datenschutz</router-link>
    </div>
  </div>
</footer>

  <!-- <GalleryLink /> -->
   <div class="bg" v-if="this.$route.name === 'Home'">
      <HomeBGSVG />
    </div>
   
  <!-- <Footer /> -->
  </div>
   
</template>
<script>

import mainConfig from '../../../main.config';
//import RouterLink from "../Components/RouterLinkComponent.vue";
import {mapState} from 'vuex';
import VRIcon from '../../SVG/VRIcon.svg';
import DesktopIcon from '../../SVG/DesktopIcon.svg';
import GalleryLink from '../../Components/gallery_360/GalleryLinkComponent.vue';
import HomeBGSVG from '../../SVG/home_bg.svg';
import IOMixins from '../../Mixins/IOMixins';
import Footer from '../Footer/Footer.vue';

export default {
  name : "Home",
  mixins: [IOMixins],
  components : {
    GalleryLink,
    VRIcon,
    DesktopIcon,
    HomeBGSVG,
    Footer
  },
  data(){
    return {
      mainConfig : mainConfig,
      btn : null,
      pageContent : {
        Subtitle : "",
        Title : ""
      }
    }
  },
  watch: {
    "$store.state.world.mainScene": function(){
      //this.AddVRButton();      
    }
  },
  mounted(){

    this.Get("/ui-section-landing").then((response)=>{
      this.pageContent = response.data;
    });
    
    
    //this.AddVRButton();    
  },
  beforeDestroy(){
    if(this.btn != null){
      this.btn.removeEventListener("click",this.Navigate);
    }
  },
  methods : {
    EnableAudio(){
      this.$store.state.audio.controller.EnableSounds();
    },
    Navigate(){

      this.$store.commit("SetXRMode", "VR");

      this.$router.push("/VR/tinycity");  

      this.$store.state.audio.controller.PlaySound("season.Summer", true);
    },
    AddVRButton(){
      navigator.xr.isSessionSupported('immersive-vr').then((supported) =>{

        if(this.btn == null && this.$store.state.world.mainScene != null && supported){

        this.btn = this.$store.state.world.mainScene.GetVRButton();

        if(this.btn == null){
          return;
        }
        this.btn.classList.add("start-button");

        this.btn.addEventListener("click",this.Navigate);
        this.$refs.VRButton.appendChild(this.btn);

      } 
			});

       
    }
  }
}
</script>

<style lang="scss">

a.start-button,.start-button {
  background: #3F4355;
  color: #fff !important;
  font-size: 1rem;
  padding:1rem 2rem !important;
  display: flex;
  border-radius: 2rem;
  align-items: center;

  button{
    color: #fff;
  }

  &:hover{
    background:#555a71;
    color:#fff;
  }
}


</style>
<style lang="scss" scoped>
@import "../../styles/imports.scss";

.badge-image {
  position: absolute;
  right: 0;
  top: 3rem;
  width: 150px;
  height: 137px;
  background-repeat: no-repeat;
  background-size: 100% auto;
}

a.imprint {
  color: #fff;
  text-decoration: underline;
}

.description{
  max-width: 500px;
}
.sponsor-logo-image{
  max-width: 250px;
  max-height: 100px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.flex.sponsors {
  pointer-events: none;
}
.sponsor-logo-image img{
  max-height: 100%;
  max-width: 100%;
  height: auto;
  display: block;
  width: auto;
}

.title-image {
  width: 60%;
  height: 100%;
  position: absolute;
  right: 0;
  max-width: 1200px;
  background-size: 90% auto;
  background-position: center;
  background-repeat: no-repeat;
}

.title-image-left {
  position: absolute;
  left: 0;
  width: 60%;
  height: 100%;
  max-width: 1200px;
  background-size: 100% auto;
  background-position: bottom;
  background-repeat: no-repeat;
}

.start-button button{
  width:100%;
  height:100%;
  display: block;

}

.inline{
  display: inline-block;
}

.content-screen.home {
  left: 2rem;
}

.home-content {
  max-width: 600px;
  position: relative;
  z-index: 2;
  h1 {
    font-size: 2.5rem;
    line-height: 2.8rem;
    margin-bottom: 2rem;
    color: $blackHeadline;
  }
  h2{
    font-size:1.5rem;
    margin-bottom: 1rem;
    color: $orange;
  }
}


.home.wrapper,footer{
  padding: 3rem;
}


.bg {
  position: absolute;
  bottom: 0;
  width: 100%;
  margin: auto;
  z-index: -1;
}

</style>

<style lang="scss">



@media (max-aspect-ratio: 1/1){

  .Home .home.wrapper{
    padding-top:150px;
  }
  .Home .wrapper-inner.flex{
    display: block;
  }
  .Home .title-image{
    height: calc(100% - 150px);
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    background-position: bottom;

  }

  .Home .title-image-left{
    position: absolute;
    left: 0;
    width: 40%;
    height: 40%;
    background-size: auto 100%;
    background-position: right bottom;
    background-repeat: no-repeat;
    z-index: 3;
    bottom: 0;
  }
}


/**
  Quest Default Browser Size
*/
@media screen and (max-width:1120px ) and (max-height:540px) {

  #app header{
    padding: 2rem ​3rem;
  }

  .Home .title-image {
    
    background-size: auto 80%;
    background-position: top center;
  }
  .Home .title-image-left{
    width: 30%;
    background-position: left bottom;
  }

  .Home .bg{
    bottom: -40%;
  }

  .Home .home-content h2{
    font-size: 1.2rem;
    margin-bottom: 0.2rem;
  }

  .home-content h1 {
    font-size: 1.9rem;
    line-height: 2.2rem;
  }

  .sponsor-logo-image{
    max-width: 100px;
    max-height: 50px;
  }
  // body{
  //   background: red!important;
  // }
}

</style>