<template>
<div class="card footer-link pointer-all">
  <button class="mute-button" @click="ToggleSound">
    <span v-if="$store.state.audio.mute"><AudioOff /></span>
    <span v-if="!$store.state.audio.mute"><AudioOn /></span>
  </button>

  <router-link class="imprint" to="/imprint">Impressum</router-link>
</div>
</template>

<script>


import AudioOn from '../../SVG/Mainmenu/SoundOn_Icon.svg';
import AudioOff from '../../SVG/Mainmenu/SoundOff_Icon.svg';

export default {
  name :"Footer",
  components:{
    AudioOn,
    AudioOff
  },
  methods: {
     ToggleSound(){
      this.$store.commit("audio/ToggleSound" , !this.$store.state.audio.mute);
    }
  },
}
</script>

<style lang="scss">
.footer-link{
  position: fixed;
  right: 1rem;
  bottom: 1rem;
  padding: 0.5rem;
  text-align: center;
  z-index: 991;
  display: flex;
  width:calc(20% - 2rem);
  align-items: center;
  a{
    font-size: 80%;
    color: $orange;
    margin: 0;
  }
}

.mute-button{
  width: 40px;
  height: 40px;
  display: inline-block;
  margin-right: 1rem;
  background: #fff;
  border-radius: 50%;
  padding: 0.4rem;
  box-shadow: -3px 3px 3px 0 rgba(0,0,0,.25);
}

@media screen and (max-width: 1200px), screen and (max-height: 800px) {
  .footer-link{
    width: calc(250px - 2rem);
  } 
}

</style>