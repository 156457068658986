<template>
  <div class="card">
      <RouterLink :to="'/desktop/'+areaData.routeParam+'/configurator'">{{areaData.Title}}</RouterLink>
      <p>{{areaData.Description}}</p>
  </div>
</template>

<script>

export default {
  name: "AreaInfo",
  mixins: [],
  props: {
      areaData:null
  },
  data() {
    return {
    };
  },
  beforeMount() {},
  mounted() {

  },
  methods: {},
  watch: {},
};
</script>
<style lang="scss" scoped>


a {
  margin-right: 50px;
}
</style>