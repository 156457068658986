<template>
  <main v-if="pageContent != null">
    <div :class="'imprint pointer-all card-wrapper flex transparent-' + CheckForTransparency ">
      <div class="width-6 width-11-m content flex flex-align-start">
        <div class="overflow card card-large">
          <div class="flex flex-align-right">
            <button class="cta-button" @click="NavigateToLastPage">zurück</button>
          </div>
          <h1>{{pageContent.Title}}</h1>
          <VueMarkdown 
          class="article-content-inner"
          :source="pageContent.Description"></VueMarkdown>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import IOMixins from '../../Mixins/IOMixins'
import config from '../../../main.config';
import VueMarkdown from '@/Components/vue-markdown.vue';

export default {
  name :"Imprint",
  mixins: [IOMixins],
  components:{VueMarkdown},
  data(){
    return {
      pageContent : null,
      from: null
    }
  },
  beforeRouteEnter(to, from, next) {
      next((vm) => {
        vm.from = from;
      });
  },
  mounted(){
  
    this.Get("/ui-section-imprint").then((response)=>{
      this.pageContent = response.data;
    });
  },
  computed:{
    CheckForTransparency:function(){
      // Check auf welcher Seite
      // Check ob Modelle in der (falls vorhanden) Area geladen sind
      //  
      return false;
    }
  },
  methods:{
    NavigateToLastPage(){

      var potentials = ["Home", "Landing" , "Explorer","ExplorerSingle", "Configurator", "Dashboard"];
      if(this.from != null && potentials.includes(this.from.name)){
        this.$router.go(-1);
      }else{
        this.$router.push("/");
      }

    },
  }
}
</script>

<style lang="scss">
.overflow{
  overflow : scroll;
}
.imprint {
  margin: 3rem auto;

  .card{
    margin: 2rem auto;
  }
}
</style>